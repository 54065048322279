import authApi from "@api/authApi";
import { promptIdeals } from "@astronautchat/common";
import { NotifyAstronautProps } from "@astronautchat/common-backend";
import {
  CommunityEntity,
  ConversationEntity,
  ConversationItemEntity,
  CustomerEntity,
  CustomerUserEntityWithRelations,
  DiscordChannelEntity,
  MessageEntity,
  PlatformDiscordEntity,
  PlatformEntity,
  PromptTestResultEntity,
  PromptVersion,
  UserEntity,
} from "@astronautchat/schema";
import { AdminMetricData, LabelResponse, QuestionsAnswersResponse } from "@custom-types/admin";
import { CircleMessage } from "@pages/astrosam";
import log from "@services/Log";

export type GetCustomerUsersAPI = Omit<CustomerUserEntityWithRelations, "customer"> & {
  customer: CustomerEntity & {
    communities: (CommunityEntity & {
      platforms: (PlatformEntity & { platformDiscord: PlatformDiscordEntity })[];
    })[];
  };
};

/** @todo add to common */
export type ConversationWithRelations = ConversationEntity & {
  conversationItems: ConversationItemEntity[];
  user: UserEntity;
};

const AdminService = {
  getCustomerUsers: async (): Promise<GetCustomerUsersAPI[]> => {
    log.info("Getting the CustomerUser objects");
    const res = await authApi.get(`/admin/customer/users`);
    return res.data;
  },
  getGraphReport: async (): Promise<any> => {
    const res = await authApi.get(`/admin/graph`);
    return { rows: res.data } as { rows: any[] };
  },
  getGraphReportAugust: async (): Promise<any> => {
    const res = await authApi.get(`/admin/graph-august`);
    return { rows: res.data } as { rows: any[] };
  },
  forceMission: async (): Promise<void> => {
    log.info("Forcing mission");
    const res = await authApi.post(`/admin/mission`, {
      missionId: "clliiy71u0008qq0hks13xwj3",
      missionType: "connect_members",
      incomingPlatformEventId: "jwup031eawylbfelwyfh6s49",
    });
    return res.data;
  },
  getPlatforms: async (): Promise<PlatformEntity[]> => {
    log.info("Getting Platforms");
    const res = await authApi.get(`/admin/platforms`);
    return res.data;
  },
  runTests: async ({
    platformId,
    version,
  }: Pick<PlatformEntity, "platformId"> & { version: PromptVersion }): Promise<void> => {
    for (var i = 0; i < promptIdeals.length; i++) {
      const prompt = promptIdeals[i].prompt;
      log.info("Running Test", { platformId, version, prompt });
      authApi.post(`/admin/tests/${platformId}`, { version, prompt });
    }

    return;
  },
  getPromptTestResults: async (): Promise<PromptTestResultEntity[]> => {
    log.info("Getting PrompTestResults");
    const res = await authApi.get(`/admin/tests/results`);
    return res.data;
  },
  getConversations: async ({
    take,
    skip,
  }: {
    take?: number;
    skip?: number;
  }): Promise<ConversationWithRelations[]> => {
    log.info("Getting Conversations");
    const res = await authApi.get(`/admin/conversations`, { params: { take, skip } });
    return res.data;
  },
  deleteCustomer: async ({
    secret,
    customerId,
    customerName,
  }: {
    secret: string;
    customerId: CustomerEntity["customerId"];
    customerName: CustomerEntity["name"];
  }): Promise<void> => {
    log.info("Deleting the Customer", { customerId, customerName });
    const res = await authApi.post(`/admin/c/h/d`, {
      secret,
      customerId,
      name: customerName,
    });
    return res.data;
  },
  getMetrics: async (): Promise<AdminMetricData[]> => {
    log.info("Getting the metrics for customers and platforms");
    const res = await authApi.get(`/admin/customer/metrics`);
    return res.data;
  },
  getCircleMessages: async ({
    discordChannelId,
  }: Pick<DiscordChannelEntity, "discordChannelId">): Promise<CircleMessage[]> => {
    log.info("Getting the CircleMessages objects");
    const res = await authApi.get(`/platform/astrosam/messages/${discordChannelId}`);
    return res.data;
  },
  getCircleChannels: async (): Promise<DiscordChannelEntity[]> => {
    log.info("Getting the CircleChannel objects");
    const res = await authApi.get(`/platform/astrosam/channels`);
    return res.data;
  },
  getLabels: async ({ take, skip }: { take: number; skip: number }): Promise<LabelResponse[]> => {
    log.info("Getting Messages with Labels");
    const res = await authApi.get(`/admin/labels`, { params: { take, skip } });
    return res.data;
  },
  getQuestionsAndAnswers: async ({
    take,
    skip,
    communityId,
    platformId,
  }: {
    take: number;
    skip: number;
    communityId: string;
    platformId?: string;
  }): Promise<{ rows: QuestionsAnswersResponse[]; rowCount: number }> => {
    log.info("Getting Questions & Answers", { take, skip, communityId, platformId });
    const res = await authApi.get(`/admin/qa`, { params: { take, skip, communityId, platformId } });
    return res.data;
  },
  notifyAstronaut: async ({ type, data }: NotifyAstronautProps) => {
    if (process.env.NODE_ENV === "development") {
      return;
    }

    log.info("Notifying Astronaut");
    const res = await authApi.post(`/admin/notify`, { type, data });
    return res.data;
  },
  getCommunities: async (): Promise<{ communityId: string; name: string }[]> => {
    log.info("Getting Communities");
    const res = await authApi.get(`/admin/communities`);
    return res.data;
  },
  getCommunityPlatforms: async ({
    communityId,
  }: Pick<CommunityEntity, "communityId">): Promise<{ platformId: string; name: string }[]> => {
    log.info("Getting Community Platforms", { communityId });
    const res = await authApi.get(`/community/${communityId}/platforms`);
    return res.data;
  },
  getMessagesBetweenDates: async ({
    questionMessageId,
    answerMessageId,
    platformId,
  }: {
    questionMessageId: string;
    answerMessageId: string;
    platformId: string;
  }): Promise<MessageEntity[]> => {
    const res = await authApi.post(`/admin/messages/between`, {
      questionMessageId,
      answerMessageId,
      platformId,
    });
    return res.data;
  },
  deleteAnswer: async ({ answerId }: { answerId: string }): Promise<void> => {
    log.info("Deleting Answer", { answerId });
    const res = await authApi.delete(`/admin/answer`, { data: { answerId } });
    return res.data;
  },
  getGraphMessages: async (): Promise<{
    platformsLast7Days: string[];
    platformsLast24Hrs: string[];
    messages: {
      messageId: string;
      content: string | null;
      platformName: string;
      originalCreatedAt: Date;
      isQuestion: boolean;
    }[];
  }> => {
    log.info("Getting Graph Messages");
    const res = await authApi.get(`/admin/graph/messages`);

    const data = res.data as {
      messageId: string;
      content: string | null;
      platformName: string;
      originalCreatedAt: Date;
      isQuestion: boolean;
    }[];

    // @ts-ignore
    const uniquePlatformsLast7Days = [...new Set(data.map((message) => message.platformName))];

    const uniquePlatformsLast24Hrs = [
      // @ts-ignore
      ...new Set(
        data
          .filter((message) => {
            const messageDate = new Date(message.originalCreatedAt);
            const oneDayAgo = new Date();
            oneDayAgo.setDate(oneDayAgo.getDate() - 1);
            return messageDate >= oneDayAgo;
          })
          .map((message) => message.platformName),
      ),
    ];

    return {
      platformsLast7Days: uniquePlatformsLast7Days,
      platformsLast24Hrs: uniquePlatformsLast24Hrs,
      messages: data,
    };
  },
};

export default AdminService;
